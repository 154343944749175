/*
 * [ modules / mondial relay ]
 */

// -----------------------------------------------------------------------------

// titre mondial relais
.MR-Widget .MRW-Title {
    color: #fff !important;
    font-weight: 500 !important;;
    background: $blue-sdi !important;
    text-transform: uppercase !important;
}

// nom des points relais
.MR-Widget .PR-Name {
    color: $blue-sdi !important;
}

#checkout a#close_fancybox {
    background: $blue-sdi;
    padding: 10px;
    color: #fff;
    font-weight: bold;
}

#checkout a#close_fancybox:hover {
    background: $blue-sdi-hover;
}

.MR-Widget.MR-Widget-Responsive .MRW-ButtonContainer button {
    background-color: $blue-sdi !important;
    border: solid 1px $blue-sdi !important;
    border-radius: 0 !important;
    width: 100% !important;
}

// Points relais sélectionné
#relay_point_selected_box {
    border: 2px solid $blue-sdi;
    padding: 10px 10px;
}

@media#{$md-down} {

    .leaflet-popup-content {
        max-width: 230px;
    }

    body .MR-Widget.MR-Widget-Responsive .leaflet-popup-content .InfoWindow {
        max-width: 100%;
    }

    .leaflet-left {
        right: 7px !important;
        left: inherit !important;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-Search { /* bloc search ville et cp */
        border: 1px solid #dcdcdc !important;
        margin-bottom: 20px !important;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-Search .MRW-Line .MRW-CityContainer input {
        width: 80px !important;
    }


    .MR-Widget.MR-Widget-Responsive {
        font-size: 10px !important;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-ShowList {
        right: initial !important;
        bottom: initial !important;
        top: 0px !important;
        left: 10px !important;
        z-index: 1111 !important;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-BtGeoGo {
        right: initial !important;
        top: 0px !important;
        z-index: 1120 !important;
        left: 65px !important;
    }

    .MR-Widget .PR-List-Item:first-child {
        margin-top: 50px;
    }

    .PR-List-Item div {
        font-size: 10px;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle {
        border: solid 1px $blue-sdi !important;
        background-color: $blue-sdi !important;
        height: 40px !important;
        width: 40px !important;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-BtGeoGo span {
        height: 25px !important;
        width: 25px !important;
        background-size: cover;
    }

    .MR-Widget.MR-Widget-Responsive .MRW-ButtonCircle.MRW-ShowList span {
        height: 25px !important;
        width: 25px !important;
        background-size: cover;
    }
}