/*
 * [ components / product devis form]
 */

// -----------------------------------------------------------------------------
// Container
#form-devis-container {
    background-color: $blue-sdi-light;
    margin-bottom: 3rem;

    form {
        max-width: 1440px;
        margin: 0 auto;
        padding: 15px;

        .recaptchaContainer {
            transform:scale(0.8);
            transform-origin:0 0;

            iframe {
                height: 78px;
            }
        }
    }
}

// Title
#form-devis-container h2 span {
    background-color: $blue-sdi-light;
}

// Form
.form-devis {
    @include flex(row, null, null, wrap);
    padding: 20px;

    // Col left
    .form-devis-col-left {
        margin-right: 2rem;
        width: 346px;
    }

    // Col right
    .form-devis-col-right {
        width: 346px;
    }

    // Form field
    .form-group {
        &:last-child {
            margin-bottom: 0;
        }

        .form-control {
            background: $white;
            color: $blue-sdi;
            border: 1px solid $blue-sdi;
            font-weight: 700;

            &::placeholder {
                color: $blue-sdi;
                font-weight: 400;
            }
        }

        textarea {
            min-height: 146px;
        }
    }

    // Button submit
    input[type=submit] {
        width: 100%;
        background-color: #61c25b;

        &:hover {
            background-color: #2DAE24;
        }
    }
}