/*
 * [ layout / slide ]
 */

// -----------------------------------------------------------------------------

.slide-container {
    position: relative;
    background: url("../../assets/img/background-presentation.jpg") no-repeat center center;
    height: 374px;
    width: 100%;
    background-size: cover;
    z-index: 5;
    @include flex(row, center, center, nowrap);

    @media #{$md-down} {
        display: inherit;
        height: 414px;
    }

    > div {
        z-index: 6;
    }

    &:after {
        content: '';
        background: rgba(23, 132, 167, 0.5);
        @include absolute(0 0 0 0);
    }
}

.slide-bloc-left {
    width: 50%;
    padding-right: 50px;

    @media #{$md-down} {
        width: auto;
        text-align: center;
        padding-right: 0;
        padding-left: 0;
    }

    h1 {
        margin: 0;
        color: white;
        font-size: 44px;
        font-family: 'RobotoSlab', serif;
        position: relative;
        text-transform: uppercase;

        @media #{$md-down} {
            font-size: 28px;
            padding-top: 1rem;
        }

        @media #{$sm-down} {
            font-size: 20px;
        }

        &:before {
            content: '';
            @include absolute(10px null 7px -40px);
            background-color: $brand-secondary;
            width: 10px;

            @media #{$md-down} {
                position: static;
            }
        }
    }
}

.slide-bloc-right {
    width: 280px;
    padding: 20px;

    @media #{$md-down} {
        width: 320px;
        margin: 1.5rem auto;
    }

    &:before {
        content: '';
        @include absolute(0 0 0 -10px);
        background-color: rgba(17, 93, 122, 0.6);
        transform: rotateZ(-4deg);
    }

    p {
        color: $white;
        position: relative;
    }

    ul li {
        color: $white;
        position: relative;
        text-transform: uppercase;

        &:before {
            content: '';
            background-color: $brand-secondary;
            width: 10px;
            height: 10px;
            @include absolute(49% null null -20px);
            transform: translateY(-50%);
        }
    }
}
