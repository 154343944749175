@import '../../../css/0-settings/import.scss';

._column {

    &:not(.column-special) {
        flex: 1 0 auto;
    }

    &:not(:last-child) {
        border-right: solid 1px black(20%);
    }
}

@media #{$breakpoints-menu-down} {
    ._column.column-special {
        width: 100%;
        order: 1;
        display: block !important;
    }

    ._column {
        width: 100% !important;
        border: none !important;
        display: block !important;
    }
}
