/*
 * [ utilities / grid ]
 */

// -----------------------------------------------------------------------------

.u-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: ($grid-gap / -2);
    margin-right: ($grid-gap / -2);
}

[class*='u-col-'] {
    width: 100%;
    min-height: 1px;
    padding-left: ($grid-gap / 2);
    padding-right: ($grid-gap / 2);
}

@for $i from 1 through $grid-columns {
    .u-col-#{$i} {
        flex: 0 0 percentage($i / $grid-columns);
        max-width: percentage($i / $grid-columns);
    }
}

@media #{$sm-up} {
    @for $i from 1 through $grid-columns {
        .sm#{':'}u-col-#{$i} {
            flex: 0 0 percentage($i / $grid-columns);
            max-width: percentage($i / $grid-columns);
        }
    }
}

@media #{$md-up} {
    @for $i from 1 through $grid-columns {
        .md#{':'}u-col-#{$i} {
            flex: 0 0 percentage($i / $grid-columns);
            max-width: percentage($i / $grid-columns);
        }
    }
}

@media #{$lg-up} {
    @for $i from 1 through $grid-columns {
        .lg#{':'}u-col-#{$i} {
            flex: 0 0 percentage($i / $grid-columns);
            max-width: percentage($i / $grid-columns);
        }
    }
}

@media #{$xl-up} {
    @for $i from 1 through $grid-columns {
        .xl#{':'}u-col-#{$i} {
            flex: 0 0 percentage($i / $grid-columns);
            max-width: percentage($i / $grid-columns);
        }

    }
}
