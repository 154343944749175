/*
 * [ settings / path ]
 */

// -----------------------------------------------------------------------------

$fonts-path:  '../fonts/';

@function font($file) {
    @return url($fonts-path + $file);
}
