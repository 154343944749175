/*
 * [ components / product short description ]
 */

// -----------------------------------------------------------------------------

// Product initial
.description-short-initial {
    margin: 3rem 0;

    p {
        color: $blue-sdi;
        line-height: 26px;
        font-size: 1rem;
    }
}

// Product devis
.description-short-devis {
    background-color: $blue-sdi-light;
    padding: 2rem;
    margin-bottom: 2rem;

    p, p span {
        color: $blue-sdi;
        line-height: 26px;
        font-size: 1rem;
    }
}