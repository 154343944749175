/*
 * [ components / products miniature ]
 */
// -----------------------------------------------------------------------------

.featured-products, #products {
    .products {
        @include flex(row, flex-start, null, wrap);
        @media #{$xl-down} {
            @include flex(row, center, null, wrap);
        }
    }
}

// Thumbnail
#products .product-miniature, .product-miniature {
    margin: 0 12px 0 0;

    .thumbnail-container {
        width: 330px;
        height: auto;
        transition: $transition;

        @media #{$sm-down} {
            width: 300px;
        }

        &:hover {
            box-shadow: 2px 2px 8px 0 rgba(0,0,0,.5);
        }

        a {
            position: relative;

            .product-stocked {
                content: '';
                @include absolute(null null 0 0);
                background: rgba(44, 151, 36, 0.8);
                padding: 5px 10px;
                color: $white;
            }

            .product-sur-mesure {
                content: '';
                @include absolute(null null 0 0);
                background: rgba(195, 166, 69, 0.8);
                padding: 5px 10px;
                color: $white;
            }
        }
    }
}

// Product image miniature
#products .product-miniature img, .product-miniature img {
    margin-left: 0;
    width: 100%;
}

// Product description container
#products .product-miniature .product-description, .product-miniature .product-description {
    display: block;
    position: static;
    padding: 0 20px;
    width: 100%;
    height: auto;
    min-height: 130px;
}

.featured-products .thumbnail-container .product-description {
    @media #{$md-down} {
        box-shadow: none;
    }
}

// Product title
#products .product-miniature .product-title, .product-miniature .product-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $blue-sdi;
    text-transform: none;

    a {
        color: $blue-sdi;
        font-size: 15px;
        font-weight: 600;
    }
}

// Product price
#products .product-miniature .product-price-and-shipping, .product-miniature .product-price-and-shipping {
    border-top: 1px solid $blue-sdi;
    padding-top: 10px;

    .price-accroche {
        color: $blue-sdi;
        margin-bottom: 0;
    }

    .price {
        color: $blue-sdi;
        font-size: 25px;
        font-weight: 600;

        > span {
            font-size: 0.9rem;
            text-transform: uppercase;
        }

        /*span.package {
            position: relative;
            color: $white;
            padding: 0 3px;
            z-index: 0;

            &:before {
                content: '';
                @include absolute(0 0 0 0);
                background-color: $brand-secondary;
                z-index: -1;
            }
        }*/
    }
}

// Encart category top left of product
#products .product-of-category, .product-of-category {
    @include absolute(0 null null 0);
    content: '';
    color: $white;
    padding: 5px 20px;
    background-color: rgba(0, 0, 0, 0.7)
}