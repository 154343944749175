@import '../../../css/0-settings/import.scss';

#app-menu {
    z-index: 1;
    flex: 1 0 auto;

    @media #{$breakpoints-menu-down} {
        @include absolute(100% 0 null 0);
        transform: rotateX(0deg);
        transform-origin: top center;
        opacity: 1;
        z-index: 10;
        background: $white;
        transition: $transition;

        &:not(.menu-is-open) {
            transform: rotateX(-90deg);
            opacity: 0;
        }
    }

    @media #{$breakpoints-menu-up} {
        height: 100%;
        border-right: solid 2px $brand-primary;
    }
}


._header-nav {
    max-width: 1440px;
    margin: 0 auto;

    @media #{$breakpoints-menu-up} {
        height: 100%;
    }
}


._menus {
    width: 100%;
    z-index: 15;

    @media #{$breakpoints-menu-up} {
        @include absolute(100% 0);
    }
}

._menu {
    @include flex(row, $wrap: wrap);
    max-width: 1440px;
    margin: 0 auto;
    border: solid 2px $blue-sdi;
    background: $white;
    transition: $transition;

    @media #{$breakpoints-menu-down} {
        display: block;
        border: none;

        &:not(.is-active) {
            display: none;
        }
    }

    @media #{$breakpoints-menu-up} {
        @include absolute(0 0 null 0);
        min-height: 240px;
        transform-origin: top center;
        transform: rotateX(0deg);
        opacity: 1;

        &:not(.is-active) {
            transform: rotateX(-90deg);
            opacity: 0;
        }
    }
}

// Burger menu container
.burger-menu {
    @include flex(row, center, center, null);
    width: 64px;
    height: 64px;

    @media #{$breakpoints-menu-up} {
        display: none;
    }
}

// Menu icon (toggle)
.burger-menu {
    i {
        &:before {
            font-size: 26px;
            color: $blue-sdi;
        }
    }
}

// Tunnel de commande
.page-order #app-menu {
    display: none;
}