/*
 * [ modules / advanced search ]
 */
// -----------------------------------------------------------------------------

// Filter content
.card {
    box-shadow: none;

    // Filter Header
    .card-header {
        background-color: $blue-sdi-light;

        span {
            color: $blue-sdi;
        }
    }

    p.h4 {
        color: $blue-sdi;
    }
}
