/*
 * [ base / default ]
 */

// -----------------------------------------------------------------------------

*,
:before,
:after {
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

html,
body {
    min-height: 100vh;
    height: auto;
    overflow-x: hidden;
}

body {
    line-height: $line-height-base;
    font-family: $font-core;
    font-weight: $font-weight-base;
    background-color: $color-body;
    color: $color-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: $blue-sdi;

    &:hover {
        color: $blue-sdi-hover;
    }
}

.btn-primary {
    background-color: $blue-sdi;

    &:hover {
        background-color: $blue-sdi-hover;
    }
}

:focus:not(:focus-visible) {
    outline: none;
}

@media #{$xl-up} {
    .container {
        width: 1440px;
    }
}

#wrapper {
    padding-top: 2.5rem;
}

#index {
    #wrapper {
        padding-top: 0;
    }
}

#wrapper {
    background: $white;
    box-shadow: none;
}

// Container full width of the window
.o-full {
    width: 100vw;
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
}