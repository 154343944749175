/*
 * [ settings / functions ]
 */

// -----------------------------------------------------------------------------

// 1. Text
// ====================================

@mixin truncate($max-width: null) {
    max-width: $max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin hyphenate {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}



// 2. Box model
// ====================================

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin ratio($x, $y) {
    padding-top: percentage($y / $x);
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}



// 3. Positioning
// ====================================

@mixin position($position, $offsets) {
    $properties: ('position', 'top', 'right', 'bottom', 'left');
    $values: join($position, $offsets);
    $declarations: zip($properties, $values);

    @each $property, $value in $declarations {
        #{$property}: $value;
    }
}

@mixin absolute($values) {
    @include position(absolute, $values);
}

@mixin fixed($values) {
    @include position(fixed, $values);
}

@mixin relative($values) {
    @include position(relative, $values);
}

@mixin center($position) {
    @include position($position, 50% null null 50%);
    transform: translate(-50%, -50%);
}

@mixin cover($position) {
    @include position($position, 0 0 0 0);
}



// 4. Layout
// ====================================

@mixin flex($dir, $justify: null, $align: null, $wrap: null) {
    display: flex;
    flex-direction: $dir;
    justify-content: $justify;
    align-items: $align;
    flex-wrap: $wrap;
}