/*
 * [ elements / list ]
 */

// -----------------------------------------------------------------------------

ul, ol {
    padding-left: 1.2em;
}

// List style
.description-short-devis,
.description-short-initial,
.description-long-devis,
.tab-content #tab-2 {
    ul {
        li {
            color: $blue-sdi;
            position: relative;

            &:before {
                content: '';
                @include absolute(50% null null -18px);
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                background-color: $brand-secondary;
            }
        }
    }
}