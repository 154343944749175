/*
 * [ settings / functions ]
 */

// -----------------------------------------------------------------------------

// 1. Utilities
// ====================================

@function str-unicode($str) {
    @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}



// 2. Colors
// ====================================

@function white($alpha) {
    @return rgba($white, ($alpha / 100%));
}

@function black($alpha) {
    @return rgba($black, ($alpha / 100%));
}

@function tint($color, $percent) {
    @return mix($white, $color, $percent);
}

@function shade($color, $percent) {
    @return mix($black, $color, $percent);
}



// 3. Maps fetching
// ====================================

@function index($key) {
    @return map-get($z-index, $key);
}

@function heading($key) {
    @return map-get($headings, $key);
}

@function unicode($icon) {
    @return str-unicode(quote(map-get($unicodes, $icon)));
}
