/*
 * [ layout / menu ]
 */

// -----------------------------------------------------------------------------

// Container des deux blocs
.secteur-metier-categories {
    margin-bottom: 60px;
    background-color: $blue-sdi-light;

    .container {
        @include flex(row, center, stretch, nowrap);

        @media #{$lg-down} {
            display: block;
            margin: 0;
            width: 100%;
            padding: 0;
        }

        > div {
            display: block;

            @media #{$lg-down} {
                padding: 35px;
            }

            h2 {
                color: $blue-sdi;
                font-family: 'RobotoSlab';
                font-size: 21px;
                text-transform: uppercase;
                position: relative;

                &:before {
                    content: '';
                    @include absolute(0 0 0 -20px);
                    background-color: #DEB531;
                    width: 5px;
                    z-index: 1;

                    @media #{$lg-down} {
                        @include absolute(100% null null 50%);
                        transform: translateX(-50%);
                        width: 30px;
                        height: 5px;
                    }
                }

                @media #{$lg-down} {
                    text-align: center;
                }
            }
        }
    }

}

// QUI ETES VOUS / container
.qui-etes-vous {
    width: 30%;
    background-color: $blue-sdi-light;
    position: relative;
    padding: 30px 0;

    @media #{$lg-xl-down} {
        width: 40%;
    }

    @media #{$lg-down} {
        width: 100%;
    }
}

// QUI ETES VOUS / Content
.qui-etes-vous {
    .content-qev {
        width: 256px;
        background-color: $blue-sdi-light;
        padding: 10px;
        margin: 20px 0;

        @media #{$lg-down} {
            margin: 30px auto;
        }

        // Image
        img {
            margin: 25px auto 0;
            display: block;
        }

        // Texte
        p {
            color: $blue-sdi;
            font-family: 'RobotoSlab';
            font-size: 19px;
            margin: 1rem 0;
            text-align: center;
            width: 230px;
        }
    }
}

// QUI ETES VOUS / select option
.qui-etes-vous {
    .select-container {
        width: 244px;
        margin: 0 0 1rem;

        @media #{$lg-down} {
            margin: 0 auto 1rem;
        }

        select {
            border: 2px solid $blue-sdi;
            border-right: transparent;
            font-size: 0.9rem;

            .option-value {
                background-color: rgba(255, 255, 255, 0.8);
            }
        }

        // Button validate filter
        .validate-filter {
            background-color: $blue-sdi;

            &:hover {
                background-color: $blue-sdi-hover;
            }
        }
    }
}

// OU
.or {
    display: flex !important;
    @include flex(column, flex-start, center, nowrap);
    position: relative;
    justify-content: center !important;

    @media #{$lg-down} {
        padding: 0 !important;
    }

    .or-span {
        position: absolute;
        line-height: 50px;
        text-align: center;
        z-index: 1;
        font-size: 1.3rem;
        color: white;
        width: 60px;

        &:before {
            @include absolute(0 0 0 0);
            content: '';
            z-index: -1;
            transform: rotateZ(-10deg);
            background: #6DBAEE;
            color: #ffffff;
        }
    }
}

// QUE SOUHAITEZ VOUS / container
.que-souhaitez-vous {
    width: 70%;
    background-color: #F2FBFE;
    padding: 30px 30px 30px 100px;
    position: relative;

    @media #{$lg-xl-down} {
        width: 60%;
    }

    @media #{$lg-down} {
        width: 100%;
    }

    &:after {
        content: '';
        @include absolute(0 -100% null null);
        background-color: #F2FBFE;
        height: 100%;
        width: 100%;

        @media #{$lg-down} {
            display: none;
        }
    }

    .qsv-container {
        margin-top: 30px;
        font-size: 0;
    }

    .category_block {
        min-height: 170px;
        font-size: 1rem;
        display: inline-block;
        width: 50%;
        padding: 0 15px;
        margin-bottom: 1rem;
        vertical-align: top;

        @media #{$lg-xl-down} {
            min-height: 220px;
        }

        @media #{$lg-down} {
            min-height: 170px;
        }

        @media #{$md-down} {
            width: 100%;
        }
    }
}

// QUE SOUHAITEZ VOUS / Title
.que-souhaitez-vous {
    h2 {
        @media #{$lg-down} {
            margin-top: 1rem;
        }
    }
}

// QUE SOUHAITEZ VOUS / Link titles
.que-souhaitez-vous {
    h3 {
        color: $blue-sdi;
        font-family: 'RobotoSlab';
        font-size: 15px;
        text-transform: uppercase;

        @media #{$lg-down} {
            text-align: center;
        }

        @media #{$md-down} {
            margin-top: 1rem;
        }
    }
}

// QUE SOUHAITEZ VOUS / links
.que-souhaitez-vous {
    .category_block > div {
        width: 100%;
        @media #{$lg-down} {
            text-align: center;
        }

        a {
            display: block;
            margin: 0 auto 5px;
            float: left;
            clear: left;
            color: white;
            padding: 3px 15px;
            transition: $transition;

            &:hover, &:focus {
                text-decoration: none;
                filter: brightness(1.15);
                outline: none;
            }

            @media #{$lg-down} {
                display: inline-block;
                float: none;
                clear: none;
            }
        }

        &:last-child {
            a {
                color: $blue-sdi;
                padding: 3px 0;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 0.9rem;
            }
        }
    }
}