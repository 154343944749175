/*
 * [ views / my account ]
 */

// -----------------------------------------------------------------------------

// Deconnexion
a.sign-out {
    display: inline-block;
    background: $blue-sdi;
    padding: 5px 10px;
    color: white;
    margin-bottom: 2rem;
    transition: $transition;

    &:hover {
        text-decoration: none;
        background: $blue-sdi-hover;
    }
}

// Blocs
.page-my-account #content .links {
    a {
        color: $blue-sdi;
        text-decoration: none;

        &:hover {
            color: $blue-sdi-hover;

            span.link-item i {
                color: $blue-sdi-hover;
            }
        }

        span.link-item {
            background: $blue-sdi-light;
            box-shadow: none;

            i {
                color: $blue-sdi;
            }
        }
    }
}