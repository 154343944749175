/*
 * [ elements / typography ]
 */

// -----------------------------------------------------------------------------

h1, h2, h3, h4, h5, h6 {
    line-height: $line-height-headings;
    font-family: $font-heading;
    font-weight: $font-weight-headings;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}


// Title styling h2
h2.products-section-title, .page-category h1 {
    position: relative;
    font-family: 'RobotoSlab';
    font-size: 21px;
    color: $blue-sdi;
    text-align: left !important;
    border-bottom: 1px solid #2C8CCC;
    text-transform: uppercase;

    @media #{$lg-down} {
        border-bottom: none;
    }

    &:before {
        content: '';
        @include absolute(5px null -5px 0);
        background-color: $brand-secondary;
        width: 5px;
        z-index: 1;

        @media #{$md-down} {
            display: none;
        }
    }

    span {
        background-color: #ffffff;
        padding: 0 20px;
        top: 5px;
        position: relative;

        @media #{$md-down} {
            padding: 0;
            top: 0;
        }
    }
}