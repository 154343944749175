
/*
 * [ components / breadcrumb ]
 */

// -----------------------------------------------------------------------------

// 1. Main
// ====================================

// Parent class
#wrapper {
    .breadcrumb {
        margin: 2.2rem 0;
    }
}

// Single item
#wrapper {
    .breadcrumb {
        display: none;

        li {

            &:after {
                content: '>';
                color: $blue-sdi
            }

            a {
                color: $blue-sdi;
                font-size: 0.9rem;
            }
        }
    }
}
