/*
 * [ layout / menu ]
 */

// -----------------------------------------------------------------------------

#_desktop_top_menu {
    #adtm_menu {
        box-shadow: none;
        border: none;
        margin: 0;

        #adtm_menu_inner {
            border: none;
        }
    }
}
