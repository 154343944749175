@import '../../../css/0-settings/import.scss';

// Parent class
._result {
    @include flex(row, null, center);
    padding: 8px;
    text-decoration: none !important;
    border: solid 1px transparent;

    &.is-active,
    &:hover,
    &:focus {
        background: black(5%);
        border-color: $brand-primary;
    }
}


// Image wrapper
._result-image {
    width: 60px;
    min-width: 60px;

    img {
        width: 100%;
    }

    @media #{$sm-down} {
        width: 40px;
        min-width: 40px;
    }
}


// Content wrapper
._result-content {
    padding: 0 0 0 16px;
    text-align: left;

    // > Price
    ._result-price {
        font-size: 0.875rem;
        color: $gray-4;

        &._price-crossed {
            text-decoration: line-through;
        }
    }

    ._result-price-discount {
        margin: 0 10px;
        color: $brand-second;
    }

    ._result-percent {
        position: absolute;
        right: 0;
        background: $brand-primary;
        color: $white;
        bottom: 0;
        padding: 5px;
    }
}

// > Title
._result-title {
    line-height: 1.2;
    color: tint($brand-primary, 0%);
    text-transform: lowercase;

    &:first-letter {
        text-transform: uppercase;
    }

    strong {
        color: $brand-primary;
    }
}