@import '../../../css/0-settings/import.scss';

// Search wrapper
.app-search {
    @media #{$md-up} {
        position: relative;
        width: 100%;
    }
}

._mod-search {
    @include flex(row);
    color: $white;
    width: 0;

    @media #{$md-up} {
        &.is-active {
            width: 100%;

            input {
                border-bottom: 1px solid $gray-3;
            }
        }
    }

    @media #{$md-up} {
        height: 40px;
        position: relative;
        margin: 0;
        padding: 4px 32px 4px 8px;
        background-color: #ffffff;
        float: right;
        transition: $transition;
    }

    @media #{$md-down} {
        &:not(.is-active) input {
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @media #{$md-down} {
        @include flex(column, center);
        height: 49px;
        padding: 0;
        cursor: pointer;
        transition: $transition;

        &:hover {
            color: $brand-primary !important;
        }


    }
}


// Search icon
._mod-search {
    @media #{$md-up} {
        position: relative;
    }

    &:before {
        font-size: 28px;
        color: $blue-sdi;
        @include absolute(50% 0 null null);
        transform: translateY(-50%);

        @media #{$md-down} {
            @include absolute(34px 148px null null);
        }

        @media #{$md-up} {
            margin: 0 8px 0 0;
            line-height: 31px;
        }
    }

    &:hover {
        &:before {
            cursor: pointer;
            color: $blue-sdi;
        }
    }
}


// Search input
._mod-search input {
    width: 100%;
    line-height: 38px;
    border: none;
    outline: none;
    font-size: 0.875rem;
    background: none;
    color: $gray-3;
    appearance: none;
    transition: $transition;

    @media #{$md-down} {
        @include absolute(100% null null 0);
        transform: translateX(0);
        opacity: 1;
        font-size: 16px;
        padding: 0 16px;
        border-top: 1px solid $blue-sdi;
        border-bottom: 1px solid $blue-sdi;
        background: $blue-sdi-light;
        color: $blue-sdi;
        line-height: 34px;
        z-index: 9;

        &::placeholder {
            color: $blue-sdi;
        }
    }

    &.remove-caret {
        caret-color: transparent;
    }
}

// Search Mobile to display in sm-down
.app-search-mobile {
    @media #{$md-down} {
        display: block !important;
        float: left;
        padding-left: 15px;
        background-color: $brand-primary;
        margin-top: 10px;
    }

    @media #{$sm-down} {
        margin-top: 4px;
    }
}

// Button Search
.search-button {
    background: $brand-second;
    font-size: 12px;
    border: none;
    position: absolute;
    width: 120px;
    line-height: 40px;
    right: -3px;
    top: 0;
    padding: 0;
    border-radius: 0 3px 3px 0;
    color: #fff;
    text-transform: uppercase;

    @media #{$md-down} {
        display: none;
    }

    &:hover {
        background: $brand-second-hover;
    }
}

// Button Close
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    position: relative;
    right: 20px;
}


// Results
._search-results {
    @include absolute(35px 0 auto 0);
    z-index: 99;
    transform: rotateX(0deg);
    transform-origin: top center;
    opacity: 1;
    border: solid 1px $gray-2;
    overflow-y: auto;
    transition: $transition;
    background: $white;

    &:not(.is-active) {
        transform: rotateX(-90deg);
        opacity: 0;
    }

    > * {
        position: relative;
    }

    > * + *:before {
        content: '';
        @include absolute(0 0 null 0);
        border-bottom: solid 1px black(5%);
    }

    @media #{$md-down} {
        position: fixed;
        top: 131px;
        right: 0;
        left: 0;
    }

    @media #{$sm-down} {
        top: 119px;
    }
}


// No result
._search-results-none {
    margin: 0;
    padding: 8px 16px;
}


// Spinner
._mod-search.has-spinner:before {
    @include absolute(16px 8px);
    transform: rotate(0deg);
    content: unicode('spinner');
    line-height: 1;
    font-family: $font-icon;
    color: $gray-2;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
