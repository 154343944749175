/*
 * [ components / product features ]
 */

// -----------------------------------------------------------------------------

.tabs {
    .product-features {
        margin-top: 0;

        ul.data-sheet {
            @media #{$md-down} {
                padding: 0;
            }

            li {
                color: $blue-sdi;
                position: relative;

                @media #{$md-down} {
                    padding-bottom: 15px;
                    border-bottom: 1px solid $blue-sdi;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                @media #{$lg-up} {
                    &:before {
                        content: "";
                        @include absolute(null null 2px -20px);
                        width: 5px;
                        height: 5px;
                        background-color: $blue-sdi;
                    }
                }

                > span {
                    display: inline-block;
                }
                .name-parent {
                    border-bottom: 2px dotted $blue-sdi;
                    width: 35%;
                    
                    @media #{$lg-xl-down} {
                        width: 70%;
                    }

                    @media #{$md-down} {
                        width: 100%;
                        display: block;
                        border: none;
                        font-weight: 700;
                    }

                    .name {
                        background: $blue-sdi-light;
                        position: relative;
                        top: 8px;
                        padding: 0 9px 2px 0;
                    }
                }

                .value {
                    position: relative;
                    margin-left: 10px;
                    top: 8px;
                    padding: 0 9px 2px 0;
                }
            }

        }
    }
}