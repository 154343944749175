/*
 * [ modules / newsletter ]
 */

// -----------------------------------------------------------------------------

// Container
.m-newsletter {
    margin: 3rem auto;

    @media #{$md-down} {
        margin: 2rem auto;
    }

    .block_newsletter {
        @media #{$md-down} {
            border-bottom: none;
        }

        .block_newsletter-container {
            @include flex(row, center, center, wrap);

            @media #{$sm-down} {
                @include flex(column, center, center, wrap);
            }
        }
    }
}

// Texte
.m-newsletter {
    h4 {
        margin-bottom: 0;
        font-family: 'RobotoSlab';
        font-size: 22px;
        color: $blue-sdi;
    }

    p#block-newsletter-label {
        padding-top: 0;
        margin-bottom: 0;
        color: $blue-sdi;
    }
}

// Input send mail
.m-newsletter {
    form input[type=email] {
        border: 2px solid $blue-sdi;
        border-right: transparent;
        color: $blue-sdi;
        font-weight: 700;
    }

    form button[type=submit] {
        position: absolute;
        background-color: $blue-sdi;
        bottom: 0;
        top: 0;
        right: 15px;
        color: $white;
        font-size: 22px;
        transition: $transition;

        &:hover {
            background-color: $blue-sdi-hover
        }
    }

    input.btn-subscribe {
        background-color: $blue-sdi;
        cursor: pointer;
        transition: $transition;

        &:hover {
            background-color: $blue-sdi-hover;
        }
    }
}
