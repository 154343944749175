/*
 * [ settings / typography ]
 */

// -----------------------------------------------------------------------------

$font-heading: 'Expletus', sans-serif;
$font-accent: 'Lato', Arial;
$font-core: 'Open Sans', sans-serif;

$font-icon: 'Icomoon', sans-serif !important;
$font-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-code: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;

$line-height-base: 1.5;
$line-height-headings: 1.2;

$font-weight-base: 400;
$font-weight-headings: 700;

// OLD CONFIG
$font-secondary:         'Gothic', sans-serif;
$font-secondary-cond:    'Gothic Cond', sans-serif;
$font-system:            -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-icon:              'Icomoon', sans-serif !important;

$font-size-base:         1.6rem;

$headings: (
        h1: 2.25rem,
        h2: 1.875rem,
        h3: 1.5rem,
        h4: 1.125rem,
        h5: 0.875rem,
        h6: 0.75rem,
);

$line-height-base:       1.5;
$line-height-headings:   1.2;

$font-weight-base:       400;
$font-weight-headings:   700;