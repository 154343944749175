/*
 * [ settings / icon ]
 */

// -----------------------------------------------------------------------------

$icons: (
    phone: 'e900',
    mail: 'e901',
    location: 'e902',
    send: 'e903',
    search: 'e904',
    envelope: 'e905',
    user-check: 'e906',
    user: 'e907',
    shopping-cart: 'e908',
    close: 'e909',
    menu: 'e5d2',
    spinner: 'e90a'

);

@function str-unicode($str) {
    @return unquote("\"") + unquote(str-insert($str, "\\", 1)) + unquote("\"")
}

@function unicode($icon) {
    @return str-unicode(quote(map-get($icons, $icon)));
}
