/*
 * [ objects / container ]
 */

// -----------------------------------------------------------------------------

.o-container {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 16px;

    @media #{$md-up} {
        padding: 0 40px
    }
    @media #{$xl-up} {
        padding: 0 80px
    }
}
