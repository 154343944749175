/*
 * [ modules / asdoria blog article]
 */

// -----------------------------------------------------------------------------

a.back-home {
    transition: background-color .3s ease-in;
}

.article-title {
    margin-bottom: 2rem;
    color: $blue-sdi;
    font-family: 'RobotoSlab'
}

.article-img {
    float:left;
    margin: 0 2rem 2rem 0;
    width: 50%;

    @media #{$sm-down} {
        width: 100%;
    }
}

.article-content {
    h2 {
        color: $blue-sdi;
    }

    p {
        color: $black;
    }
}

// Nav posts link
.posts-nav-header {
    text-align: center;

    > a {
        background-color: $blue-sdi;
        color: $white;
        padding: 5px 10px;
        margin: 0 5px;
        transition: background-color 0.3s ease-in;

        &:hover, &:focus {
            text-decoration: none;
            background-color: $blue-sdi-hover;
        }
    }
}