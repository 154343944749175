/*
 * [ layout / footer ]
 */

// -----------------------------------------------------------------------------

// Container
.l-footer {
    padding-top: 0 !important;
    background-color: #D6F3FE;
    margin-top: 2.5rem;
}

// Relance pour nous connaître
.intro-footer {
    background-color: #E3F8FE;
    padding: 30px 0;

    .intro-footer-container {
        @include flex(row, null, baseline, null);
        max-width: 1440px;
        margin: 20px auto;

        @media #{$sm-down} {
            @include flex(column, null, flex-start, null);
        }

        > div {
            padding-left: 30px;

            @media #{$xl-down} {
                &:first-child {
                    border-right: 1px solid $blue-sdi;
                }
            }

            @media #{$sm-down} {
                padding-left: 15px;
                margin-bottom: 15px;

                &:first-child {
                    padding-bottom: 27px;
                    border-right: none;
                    border-bottom: 1px solid $blue-sdi;
                }
            }

            @media #{$xl-up} {
                &:before {
                    content: '';
                    @include absolute(0 null 0 0);
                    background-color: $brand-secondary;
                    width: 5px;
                }
            }

            h3 {
                font-family: 'RobotoSlab', serif;
                text-transform: uppercase;
                font-size: 20px;
                color: $blue-sdi;
            }

            a {
                background: transparent;
                padding: 7px 30px;
                font-size: 14px;
                border: 2px solid $blue-sdi;
                color: $blue-sdi;
                font-weight: 700;
                text-transform: uppercase;
                transition: $transition;

                &:hover {
                    background: $blue-sdi;
                    color: $white;
                }
            }
        }
    }
}

// Footer
.footer-container {
    box-shadow: none;
    margin-top: 0;
    padding-top: 0;

    .container {
        @media #{$md-down} {
            width: 100%;
        }
        // Titles
        .footer-titles, .title .h3 {
            color: $blue-sdi !important;
            font-size: 15px;
            font-family: 'RobotoSlab', serif;
        }

        // Listing liens
        ul {
            li {
                margin-bottom: .7rem;

                a {
                    color: $blue-sdi;
                    position: relative;
                    font-size: 14px;

                    &:before {
                        content: '';
                        @include absolute(50% null null -18px);
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        background-color: $brand-secondary;
                    }

                    &:hover {
                        color: $blue-sdi-hover;
                    }
                }
            }
        }

        // Image Paiements sécurisés
        .img-methods-payment {
            margin-top: 7px;
        }
    }
}

// Methods payment
.methods-payment {
    @media #{$md-down} {
        padding: .625rem;
    }
}

.footer-container {
    .material-icons {
        color: $blue-sdi;
    }
}

// Droits
.all-rights {
    padding-top: 10px;
    border-top: 1px solid $blue-sdi;
    margin-top: 50px;

    .copyright {
        color: $blue-sdi;

        a {
            color: $blue-sdi-hover;
        }
    }
}

