/*
 * [ layout / header ]
 */

// -----------------------------------------------------------------------------

// Header container
#header {
    a {
        color: $blue-sdi;

        &:hover {
            color: $blue-sdi-hover;
        }
    }

    .header-top {
        padding: 0;
        
        @media #{$breakpoints-menu-down} {
            border-bottom: 1px solid $blue-sdi;
        }

        .container {
            width: 100%;
            background: $white;
            
            @media #{$md-down} {
                padding-right: 0;
            }
        }
    }
}

// Header top
.header-first-row {
    @include flex(row, space-between, center, nowrap);
    max-width: 1440px;
    margin: 16px auto;

    > div {
        width: 33%;
    }
}

// Logo
.header-first-row {
    .header-left {
        @media #{$breakpoints-menu-down} {
            width: 20%;
        }

        @media #{$md-down} {
            width: 150px;
        }


        h1 {
            margin: 0;
        }
    }
}

// Coordonnées - Container
.header-first-row {
    .header-center {
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$lg-to-xl} {
            width: 48%;
        }

        @media #{$lg-down} {
            display: none;
        }
    }
}

// Coordonnées - Phone
.header-center .coordonnees-left {
    position: relative;
    padding-right: 20px;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: $blue-sdi;
    }

    p {
        color: $blue-sdi;
        margin-bottom: 0;
        font-size: 20px;

        &.availabilities {
            font-size: 14px;
        }
    }
}

// Coordonnees right - Container
.coordonnees-right {
    align-self: flex-end;
}

// Coordonnées - Mail
.coordonnees-right .mail {
    position: relative;
    padding-left: 20px;
    transition: $transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;

    i {
        font-size: 16px;
        margin-bottom: 8px;
    }
}

// User + panier
.header-first-row {
    .header-right {
        text-align: center;
        @media #{$breakpoints-menu-down} {
            width: 60%;
        }

        @media #{$lg-down} {
            width: 80%;
        }

        @include flex(row, flex-end, center, nowrap);

        #_desktop_user_info, #_desktop_cart {
            i {
                display: block;
                font-size: 22px;
                margin: 0 auto;
            }
        }

        #_desktop_user_info {
            .user-info {
                width: 140px;
                padding: 0 20px 0 0;

                @media #{$md-down} {
                    width: auto;
                }

            }
        }

        #_desktop_cart {
            .header {
                position: relative;

                i {
                    font-size: 20px;
                }

                .cart-products-count {
                    @include absolute(-17px -6px);
                    font-size: 14px;
                    line-height: 20px;
                    background-color: $brand-secondary;
                    color: $white;
                    width: 30px;
                    height: 20px;

                    @media #{$md-down} {
                        @include absolute(-22px -6px);
                    }
                }
            }
        }
    }
}

// Header bottom
.header-second-row {
    background-color: $blue-sdi;
}
