/*
 * [ modules / asdoria blog list]
 */

// -----------------------------------------------------------------------------

// Container
.wrapper-listing-post {
    margin-top: 2rem;
}

.post-card {
    @media #{$lg-down} {
        margin-bottom: 2rem;
    }

    @media #{$md-down} {
        margin-bottom: 4rem;
    }
}

a.post-card-link {
    &:hover {
        text-decoration: underline;
        color: $blue-sdi;
    }
}

.post-card-thumb img {
    width: 100%;
}

.post-card-title {
    margin: 1rem 0;
    color: $blue-sdi;
    font-size: 1.1rem;
    font-weight: 700;
}

.post-card-description {
    color: $blue-sdi;
    font-size: 1rem;
}