/*
 * [ modules / ps_contactinfo ]
 */
// -----------------------------------------------------------------------------

.block-contact {
    padding-left: 0;
    border: none;
    font-size: 14px;

    @media #{$md-down} {
        padding: 10px 0;
        border-top: 1px solid $blue-sdi;
        border-bottom: 1px solid $blue-sdi;
    }

    .logo {
        width: 183px;
        margin: 2px 0 15px;

        @media #{$md-down} {
            margin-left: 10px;
        }

    }

    ul {
        padding-left: 0;

        @media #{$md-down} {
            background-color: #D6F3FE !important;
        }

        li {
            color: $blue-sdi;

            @media #{$md-down} {
                border-bottom: none !important;
            }

            .Icon-mail:before, .Icon-phone:before, .Icon-location:before {
                font-size: 18px;
                color: $brand-secondary;
                margin-right: 10px;
                vertical-align: middle;
            }

            a {
                &:before {
                    position: static !important;
                }
            }
        }
    }
}