/*
 * [ objects / cover ]
 */

// -----------------------------------------------------------------------------

.o-cover-image-landscape,
.o-cover-image-portrait {
    position: relative;
    overflow: hidden;
}

.o-cover-image-landscape img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
}

.o-cover-image-portrait img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto;
}
