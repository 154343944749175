@import '../../../css/0-settings/import.scss';

// Wrapper
._box {
    transition: $transition;

    @media #{$breakpoints-menu-up} {
        padding: 24px;
    }
}


// Wrapper [Image]
._box.is-image {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;

    img {
        @include absolute(null null 0 0);
        max-width: 90%;
        max-height: 90%;
    }
}



// Wrapper [Special]
._box.is-special {
    padding: 0;

    &:nth-child(1) {
        background: $brand-primary;
        color: $black;
    }

    &:nth-child(1):hover {
        background: tint($brand-primary, 20%);
    }

    &:nth-child(2) {
        background: $black;
        color: $white;
    }

    &:nth-child(2):hover {
        background: tint($black, 10%);
    }

    @media #{$xl-down} {
        flex: 1 0 auto;
    }

    @media #{$xl-up} {
        width: 200px;
        height: 50%;
    }
}



// Wrapper [Category]
._box.is-category {

    & + & {
        padding-top: 0;
    }

    &.is-align {
        float: left;
    }

    ul {
        @media #{$breakpoints-menu-down} {
            display: block !important;
            padding: 0;
            margin: 0;
        }
    }
}


// Title
._box-title {
    @include flex(row, null, center);
    text-decoration: none !important;

    ._box.is-special & {
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
        padding: 12px;
        font-size: 1rem;
    }

    ._box.is-category & {
        margin: 0 0 12px;
        color: $brand-primary;
    }

    @media #{$xl-up} {
        ._box.is-special & {
            padding: 24px 12px;
        }
    }

    @media #{$lg-up} {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.15;
    }

    @media #{$lg-down} {
        display: block;
        line-height: 20px;
        border-bottom: solid 1px $black;
        margin: 0 !important;
        padding: 12px 16px;
        background: $gray-2;
        color: $black !important;

        &:after {
            content: unicode('arrow-bottom');
            float: right;
            font-family: $font-icon;
            font-size: 20px;
        }

        ._box.is-active &:after {
            transform: rotate(-90deg);
        }
    }
}


// Image
._box-title img {
    width: auto;
    height: 24px;
    margin: 0 8px 0 0;

    .box.is-special & {
        height: 48px;
        margin: 0 16px 0 0;
    }

    @media #{$lg-down} {
        display: none;
    }
}


// Link li
._box-link {
    line-height: 1;
    padding: 12px 0;

    @media #{$breakpoints-menu-down} {
        padding: 0;
    }
}

// Link a
._box-link a {
    position: relative;
    font-size: 1rem;

    @media #{$breakpoints-menu-up} {
        &:before {
            content: '';
            @include absolute(50% null null -18px);
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background-color: $brand-secondary;
        }
    }

    @media #{$lg-up} {
        &:hover {
            text-decoration: underline;
        }
    }

    @media #{$breakpoints-menu-down} {
        display: block;
        line-height: 20px;
        padding: 8px 16px;
        border-bottom: solid 1px $blue-sdi;
        font-size: 1rem;
        background: $white;
        color: $blue-sdi !important;
    }
}