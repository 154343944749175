#product-modal {

    // Image
    .image-caption {
        padding: 0 !important;
        border: none;
    }

    // List thumbnails
    .product-images {
        img {
            max-width: inherit;
        }
    }
}