/*
 * [ views / cart ]
 */

// -----------------------------------------------------------------------------

.page-cart {
    //Bloc gauche
    .cart-grid-body {
        .product-line-info {
            margin-bottom: 0.5rem;

            .current-price {
                text-transform: none;
            }
        }

        a.label {
            &:hover {
                color: $blue-sdi;
            }
        }

        .input-group {
            box-shadow: none;

            .js-cart-line-product-quantity {
                border: 2px solid $blue-sdi;
                color: $blue-sdi;
                font-weight: 700;
                text-align: center;
            }

            .input-group-btn-vertical {
                button {
                    border: 2px solid $blue-sdi;
                    border-left: none;


                    i {
                        color: $blue-sdi;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.page-cart .cart-items {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        padding-left: 0;
    }
}