/*
 * [ modules / reassurance ]
 */
// -----------------------------------------------------------------------------

// All reassurance
#block-reassurance {
    .block-reassurance-item {
        span {
            color: $blue-sdi;
            margin-bottom: 0
        }
    }
}

// Reassurance Homepage
.home-top-reassurance {
    background-color: #E1F7FF;

    #block-reassurance {
        margin: 0 auto;
        max-width: 1440px;

        ul {
            @include flex(row, space-around, center, nowrap);
            padding-left: 0;
            margin-bottom: 0;

            @media #{$lg-down} {
                display: block;
                height: 68px;
                overflow: hidden;
            }

            li {
                border-bottom: none;
                transition: all 0.25s ease-out;

                &:first-child {
                    @media #{$lg-down} {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }

                @media #{$lg-down} {
                    transform: translateY(-100%);
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                }

                .block-reassurance-item {
                    @include flex(row, center, center, nowrap);
                    padding: 1rem 0;

                    img {
                        width: auto;
                    }
                }
            }

            /*li.anim-reassurance {
                transform: translateY(0);
                opacity: 1;
            }*/
        }
    }
}

// Reassurance Footer
.l-footer {
    .home-bottom-reassurance {
        background-color: #D6F3FE;

        #block-reassurance {
            text-align: center;
            margin-top: 0;

            ul {
                display: inline;
                border-bottom: 1px solid $blue-sdi;
                padding-bottom: 10px;

                @media #{$lg-xl-down} {
                    display: block;
                    padding: 0;
                }

                li {
                    display: inline-block;
                    padding-right: 50px;
                    border-bottom: none;

                    @media #{$lg-xl-down} {
                        display: block;
                        padding-right: 0;
                        border-top: 1px solid $blue-sdi;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    .block-reassurance-item {
                        @include flex(row, center, center, nowrap);
                        padding: 1rem 0;

                        img {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

// Reassurance panier
.cart-grid-right #block-reassurance {
    img {
        vertical-align: middle;
    }
}