@import '../../../css/0-settings/import.scss';

// Single items
._tab {
    padding: 0 16px;
    cursor: pointer;
    line-height: 49px;
    float: left;

    @media #{$breakpoints-menu-up} {
        padding: 0 20px;
    }

    @media #{$breakpoints-menu-down} {
        display: block;
        float: none;
        width: 100%;
        text-align: left;
        border-bottom: solid 1px $blue-sdi;
    }

    @media #{$breakpoints-menu-up} {
        &.is-active {
            background: $brand-primary;
        }
    }
}



// All
._tab > * {
    pointer-events: none;
}


// Image
._tab-image {
    margin: 0 0 12px;

    img {
        width: auto;
        height: 56px;
    }

    @media #{$breakpoints-menu-down} {
        display: none;
    }
}


// Title
._tab-title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-family: $font-secondary;
    color: $white;

    @media #{$breakpoints-menu-down} {
        line-height: 20px;
        font-size: 1rem;
        color: $blue-sdi;
    }
}


// Subtitle
._tab-subtitle {
    color: $brand-primary;

    ._tab.is-active & {
        color: $black;
    }

    @media #{$breakpoints-menu-down} {
        display: none;
    }
}