/*
 * [ views / checkout ]
 */

// -----------------------------------------------------------------------------

body#checkout {
    #header {
        .header-nav {
            padding: 20px 0;
        }
    }
}

body#checkout section.checkout-step {
    box-shadow: none;

    // Adresses
    .address-item.selected {
        border: 3px solid $blue-sdi;
    }
}

.checkout-bloc-left {
    border: 1px solid rgba(0,0,0,.25);
}

// 4. PAIEMNET
#checkout-payment-step {
    label {
        text-align: initial;
    }
}

#checkout-personal-information-step .nav-item .nav-link {
    color: #2C8CCC;
    position: relative;
    display: inline-block;
}

#checkout-personal-information-step .nav-item .nav-link:after {
    content: '';
    height: 2px;
    top: calc(100% + 2px);
    background-color: #2C8CCC;
    transition: all 0.25s ease-out;
    position: absolute;
    left: 0;
    width: 0;
}

#checkout-personal-information-step .nav-item .nav-link.active:after,
#checkout-personal-information-step .nav-item .nav-link:hover:after {
    width: 100%;
}
