/*
 * [ views / category ]
 */

// -----------------------------------------------------------------------------

.page-category .container {
    position: relative;
}


// Title
.page-category h1 {
    margin: 0 15px 30px;
}

// Reset of a block
#js-product-list-header {
    .block-category {
        min-height: auto;
        box-shadow: none;
        border: none;
        margin: 0;
        padding: 0;
    }
}

// Category description
#category-description {
    p {
        color: $blue-sdi;
    }
}

// Trier par
.products-selection .sort-by-row {
    @include flex(row, flex-end, center, null);

    button.select-title {
        line-height: 22px;
        vertical-align: middle;
        box-shadow: none;
    }

    // Dropdown menu
    .dropdown-menu {
        padding: 0;
        box-shadow: none;

        a {
            &:hover {
                background: $blue-sdi;
            }
        }
    }
}

// Pagination
.pagination {
    ul.page-list {
        box-shadow: none;
        border: 1px solid $blue-sdi;

        li.current a {
            color: $blue-sdi;
        }
    }
}