/*
 * [ settings / media ]
 */

// -----------------------------------------------------------------------------

$landscape: 'screen and (orientation: landscape)';
$portrait: 'screen and (orientation: portrait)';

$breakpoints-menu-up: 'screen and (min-width: 1320px)';
$breakpoints-menu-down: 'screen and (max-width: 1319px)';

$sm-up: 'screen and (min-width: 576px)';
$md-up: 'screen and (min-width: 768px)';
$lg-up: 'screen and (min-width: 992px)';
$lg-xl-up: 'screen and (min-width: 1200px)';
$xl-up: 'screen and (min-width: 1440px)';

$sm-down: 'screen and (max-width: 575px)';
$md-down: 'screen and (max-width: 767px)';
$lg-down: 'screen and (max-width: 991px)';
$lg-xl-down: 'screen and (max-width: 1199px)';
$xl-down: 'screen and (max-width: 1439px)';

$sm-to-md: 'screen and (min-width: 576px) and screen and (max-width: 767px)';
$md-to-lg: 'screen and (min-width: 768px) and screen and (max-width: 991px)';
$lg-to-xl: 'screen and (min-width: 992px) and (max-width: 1339px)';
