/*
 * [ utilities / spacing ]
 */

// -----------------------------------------------------------------------------

$spacing-sizes: (8, 16, 24, 32, 64);
$spacing-dirs: (top, rigth, bottom, left);

@each $size in $spacing-sizes {

    @each $dir in $spacing-dirs {
        $alias: str-slice($dir, 0, 1);

        .u-p#{$alias}#{$size} {
            padding-#{$dir}: $size * 1px;
        }

        .u-m#{$alias}#{$size} {
            margin-#{$dir}: $size * 1px;
        }
    }

}
