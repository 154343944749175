/*
 * [ components / nav tabs ]
 */

// -----------------------------------------------------------------------------

// Container
.tabs {
    margin-bottom: 4rem;
    box-shadow: none;
    background-color: $blue-sdi-light;
    padding: 0;

    .tabs-container {
        max-width: 1440px;
    }

    // Override presta
    .nav-tabs {
        border-bottom: none;
    }
}

// Nav tabs
.nav-tabs {
    @include flex(row, flex-start, null, null);
    margin-right: calc(-50vw + 50%);
    background-color: $white;

    @media #{$lg-down} {
        margin-right: 0;
    }

    > li {
        @media #{$md-down} {
            width: 50%;
        }

        > a {
            padding: 10px 30px 10px 60px;
            position: relative;
            font-family: 'RobotoSlab';
            display: block;
            font-weight: 700;
            background-color: $white;
            font-size: 1.3rem;
            color: #2C8CCC;
            text-transform: uppercase;

            @media #{$md-down} {
                height: 100%;
                padding: 10px;
                text-align: center;
            }
            @media #{$sm-down} {
                font-size: 0.9rem;
            }

            &:hover {
                text-decoration: none;

                &:before {
                    background-color: $blue-sdi;
                }

            }

            &:before {
                content: '';
                @include absolute(50% null null 35px);
                transform: translateY(-50%);
                background-color: $blue-sdi;
                height: 20px;
                width: 5px;

                @media #{$md-down} {
                    display: none;
                }
            }
        }

        &.active {
            > a,
            > a:hover,
            > a:focus {
                text-decoration: none;
                background-color: $blue-sdi-light;

                &:before {
                    background-color: $brand-secondary;
                }
            }
        }
    }
}

// Content of a tab
.tab-content {
    padding: 1rem 2rem;
    @media #{$md-down} {
        padding: 1rem;
    }

    > .tab-pane {
        display: none;

        p, p span {
            color: $blue-sdi !important;
            font-size: 1rem !important;
            font-family: 'Open Sans' !important;
        }

        &.active {
            display: block;
        }
    }

    > .active {
        display: block;
    }
}