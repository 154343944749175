/*
 * [ elements / form ]
 */
// -----------------------------------------------------------------------------

textarea {
    resize: vertical;
    overflow-y: auto;
}

// Form selected border
form input[type=email]:focus,
.form-control:focus {
    outline: .15rem solid $blue-sdi;
}

// Form calculated
input:-webkit-autofill {
    -webkit-text-fill-color: $blue-sdi;
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
}

// select option container
.select-container {
    @include flex(null, null, null, null);
    margin: 2.5em 0 0 0;
    width: 360px;
    position: relative;

    &:before {
        content: '';
        @include absolute(50% 72px);
        margin-top: -3px;
        display: block;
        width: 0; height: 0;
        border-color: transparent;
        border-top-color: $blue-sdi;
        border-width: 6px;
        border-style: solid;
        pointer-events: none;
    }

    @media #{$md-down} {
        margin: 1.5em auto 0 auto;
    }

    @media #{$sm-down} {
        width: 320px;
    }
}

// select option styling
.select-container select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: rgba(255,255,255,0.9);
    color: $blue-sdi;
    line-height: 20px;
    border: 0;
    text-transform: uppercase;
    margin: 0;
    padding: .75em;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    option.option-value {
        background-color: rgba(255,255,255,0.8);
        text-transform: none;
    }

    @media #{$sm-down} {
        font-size: 0.9rem;
    }
}

// Button validate filter
.validate-filter {
    background-color: rgba(0, 77, 128, 0.7);
    line-height: 23px;
    color: $white;
    transition: $transition;

    &:hover {
        background-color: rgba(0, 77, 128, 1);
    }
}

// select of slider
.slide-container {
    .select-container {
        &:after {
            content: '';
            @include absolute(null -100px 20px null);
            background: url("../../assets/img/reply-all-button.png");
            width: 60px;
            height: 46px;

            @media #{$lg-xl-down} {
                @include absolute(null -0px 60px null);
            }

            @media #{$md-down} {
                display: none;
            }
        }
    }
}

// Input radio
.custom-radio input[type=radio]:checked+span {
    background-color: $blue-sdi;
}
