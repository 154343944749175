/*
 * [ components / product short description ]
 */

// -----------------------------------------------------------------------------

// Product devis
.description-long-devis {
    margin: 3rem 0;

    h2 {
        margin-bottom: 1rem;
    }

    p {
        color: $blue-sdi;
        font-size: 1rem;

        span {
            color: $blue-sdi !important;
            line-height: 22px;
        }

    }
}