/*
 * [ utilities / typography ]
 */

// -----------------------------------------------------------------------------

@for $i from 1 through 9 {
    .u-fw#{$i * 100} {
        font-weight: #{$i * 100};
    }
}
