/*
 * [ settings / helpers ]
 */

// -----------------------------------------------------------------------------

%full-width {
    width: 100vw;
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
}
