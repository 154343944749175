@charset "UTF-8";

@import
  '0-settings/import';

@import
  '1-base/base-normalize',
  '1-base/base-default',
  '1-base/base-font';

@import
  '2-elements/elements-form',
  '2-elements/elements-list',
  '2-elements/elements-media',
  '2-elements/elements-typography',
  '2-elements/elements-table';

@import
  '3-components/icons',
  '3-components/components-breadcrumb',
  '3-components/components-nav-tabs',
  '3-components/components-product-description-short',
  '3-components/components-product-description-long',
  '3-components/components-product-features',
  '3-components/components-product-devis-form',
  '3-components/components-product-images-modal',
  '3-components/components-products-miniature';

@import
  '4-modules/modules-blog-list',
  '4-modules/modules-blog-article',
  '4-modules/modules-blog-summary',
  '4-modules/modules-carousel',
  '4-modules/modules-checkout-summary',
  '4-modules/modules-estimated-delivery',
  '4-modules/modules-featured-products',
  '4-modules/modules-block-contact',
  '4-modules/modules-hero',
  '4-modules/modules-mondialrelay',
  '4-modules/modules-advanced-search',
  '4-modules/modules-newsletter',
  '4-modules/modules-push',
  '4-modules/modules-reassurance';

@import
  '5-objects/objects-container',
  '5-objects/objects-cover';

@import
  '6-layout/layout-header',
  '6-layout/layout-footer',
  '6-layout/layout-menu',
  '6-layout/layout-secteur-metier-categories',
  '6-layout/layout-sidebar',
  '6-layout/layout-slide';

@import
  '7-views/views-category',
  '7-views/views-checkout',
  '7-views/views-cart',
  '7-views/views-home',
  '7-views/views-product',
  '7-views/views-my-account',
  '7-views/views-search';

@import
  '8-utilities/utilities-grid',
  '8-utilities/utilities-spacing',
  '8-utilities/utilities-typography';

@import
  '9-vendors/zoom';
