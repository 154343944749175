/*
 * [ components / icon ]
 */

// -----------------------------------------------------------------------------

[class^="Icon-"], [class*=" Icon-"] {
    font-family: $font-icon;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@each $name, $code in $icons {
    .Icon-#{$name}:before {
        content: str-unicode($code);
    }
}

// <i class="Icon-phone"></i>
/*
.Icon-add:hover{
    color: red;
}*/
