/*
 * [ modules / asdoria blog summary]
 */

// -----------------------------------------------------------------------------

#module-asdoria_blog-accueil #content {
    border: none;
}

// Title of page
.blog-header-title {
    color: $blue-sdi;
    font-family: 'RobotoSlab';
}

// Container
.post-card-hz {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-2;

    @media #{$md-down} {
        flex-direction: column;
    }
}

// Img
.post-card-hz-thumb {
    img {
        max-width: none;
        width: 320px;

        @media #{$sm-down} {
            width: 275px;
        }
    }
}

// Part right
.post-card-hz-right {
    margin-left: 2rem;

    @media #{$md-down} {
        margin-left: 0;
    }
}

// Date
.post-card-hz-date {
    display: block;
    width: 190px;
    background-color: $blue-sdi;
    color: $white;
    padding: 5px 10px;
}

.post-card-hz-content {
    margin-top: 1rem;

    // Title
    .post-card-hz-title {
        color: $blue-sdi;
        text-decoration: underline;
    }

    .post-card-hz-excerpt {
        color: $black;
    }
}

// See more
.see-more {
    transition: background-color .2s ease-in;
}

