/*
 * [ modules / estimated delivery]
 */

// -----------------------------------------------------------------------------

.estimateddelivery div {
    border: 1px solid $blue-sdi !important;
    padding: 20px !important;
}

.estimateddelivery {
    h4 {
        color: $blue-sdi;

        &:before {
            color: $blue-sdi;
        }
    }

    p {
        color: $blue-sdi;

        &:last-child {
            margin-bottom: 0;
        }
    }
}