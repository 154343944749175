/*
 * [ views / product ]
 */

// -----------------------------------------------------------------------------

// Override css presta...
#product {

    //container product image
    #content {
        max-width: 572px;
        margin: 0;
    }

    // Reassurance bottom page
    #block-reassurance {
        box-shadow: none;
        background: transparent;

        span {
            font-weight: 400;
        }
    }
}

// Container bloc left
.product-bloc-left {
    float: left;
    width: 40%;

    @media #{$md-down} {
        display: none;
    }
}

// Container bloc right
.product-bloc-right {
    float: left;
    width: 57%;
    margin-left: 3%;

    h1 {
        text-transform: none !important;
    }

    @media #{$md-down} {
        width: 100%;
        float: none;
        margin-left: 0;
    }

    .images-container {
        width: 345px;

        @media #{$md-up} {
            display: none;
        }
    }
}

// Container Bloc image
.images-container {

    // Image
    .product-cover {
        margin-bottom: 0.25rem;

        span {
            position: absolute;
            @include absolute(null null 0 0);
            color: $white;
            text-align: center;
            margin-bottom: 0;
            line-height: 36px;
            text-transform: uppercase;
            background-color: $blue-sdi;
            padding: 0 20px;
        }

        img {
            border: 1px solid $blue-sdi;
            box-shadow: none;
        }

        .layer .zoom-in {
            color: $blue-sdi;
        }
    }

    // Text indicatif pour zoom
    .text-click-zoom {
        margin-bottom: 3rem;
        text-align: center;

        @media #{$md-down} {
            display: none;
        }

        span {
            margin-left: 0.5rem
        }
    }
}

// Thumbnail selected
.thumb.selected {
    border: 3px solid $blue-sdi !important;
}

// Thumbnail no selected
.thumb {
    box-shadow: none !important;
    border: 1px solid $blue-sdi !important;

    &:hover {
        border: 3px solid $blue-sdi !important;
    }
}

// Nav Thumbnail
.scroll-box-arrows i {
    color: $blue-sdi;
}

// Product title
.product-bloc-right {
    h1 {
        margin-top: 0;
        font-family: 'RobotoSlab';
        color: $blue-sdi;
        font-size: 1.5rem;
        text-transform: capitalize;
    }
}

// Price decreasing gradually
.price-decreasing-gradually {
    background-color: $blue-sdi-light;
    padding: 20px;
    margin-bottom: 2rem;

    ul {
        padding: 0;
        margin: 0;

        li {
            @media #{$lg-xl-down} {
                padding: 10px;
                border-bottom: 1px solid $blue-sdi;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        p {
            color: $blue-sdi;
            margin-bottom: 0;
            font-size: 1rem;
        }
    }
}

// Product prices
.current-price {
    color: $blue-sdi;
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;

    .custom-price {
        font-size: 2.2rem;
        font-family: 'RobotoSlab';
        font-weight: 700;
    }

    .tax-shipping-delivery-label {
        color: $blue-sdi;
    }
}

// Réassurance product
.product-bloc-right {
    #block-reassurance {
        box-shadow: none;
        background: transparent;

        ul {
            @include flex(row, null, flex-start, null);
            padding-left: 0;

            li {
                border-bottom: none;
                width: 130px;

                .block-reassurance-item {
                    @include flex(column, center, center, null);
                    text-align: center;
                    padding-left: 0;

                    img {
                        margin: 0 0 0.5rem 0;
                        height: 25px;
                        width: auto;
                    }

                    span {
                        color: $blue-sdi;
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
}

// Product infos panier / container
.product-add-to-cart {
    .product-quantity {
        @include flex(column, null, null, null);

        span {
            font-weight: 600;
            color: $blue-sdi;
            margin-right: 1rem;
            text-transform: uppercase;
            font-size: 0.8rem;
        }
    }
}

.qty-total-price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

// Product infos panier / quantité
.product-add-to-cart {
    .qty {
        @include flex(row, null, flex-end, null);
        margin-bottom: 0.5rem;

        /*@media #{$sm-down} {
            @include flex(column, null, center, null);
        }*/

        .input-group {
            box-shadow: none;
            margin-right: 2rem;

            #quantity_wanted {
                border: 2px solid $blue-sdi;
                color: $blue-sdi;
                font-weight: 700;
                text-align: center;
            }

            .input-group-btn-vertical {
                button {
                    border: 2px solid $blue-sdi;
                    border-left: none;


                    i {
                        color: $blue-sdi;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

// Product infos panier / total price
.product-add-to-cart {
    .total-price {
        .calculated-price {
            font-family: 'RobotoSlab';
            font-size: 2rem;
            line-height: 32px;
            font-weight: 700;
            color: #2C8CCC;
        }
    }
}

// Product infos panier / ajout panier
.product-add-to-cart {
    .add {
        width: 350px;
        margin-top: 1rem;

        @media #{$sm-down} {
            width: 100%;
        }

        button {
            width: 100%;
            background-color: #61c25b;
            line-height: 22px;

            &:hover {
                background-color: #2DAE24;
            }
        }
    }
}

// Infos min qty
.product-minimal-quantity {
    font-weight: 700;
    color: $blue-sdi;
    font-size: 16px;
}

// Button Demander un devis
.ask-devis {
    button {
        width: 100%;
        background-color: #61c25b;
        line-height: 22px;

        &:hover {
            background-color: #2DAE24;
        }

        &:active, &:focus {
            background-color: #61c25b;

            &:hover {
                background-color: #61c25b;
            }
        }
    }
}

// Global
.product-price {
    color: $blue-sdi;
}

.product-additional-info {
    margin-top: 2rem;
}