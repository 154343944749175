/*
 * [ Base > Fonts ]
 */

// -----------------------------------------------------------------------------

// 1. Roboto Slab
// ====================================

@font-face {
    font-family: 'RobotoSlab';
    font-weight: 400;
    font-style: normal;
    src: font('roboto-slab/RobotoSlab-Regular.eot');
    src: font('roboto-slab/RobotoSlab-Regular.woff2') format('woff2'),
         font('roboto-slab/RobotoSlab-Regular.woff') format('woff'),
         font('roboto-slab/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
         font('roboto-slab/RobotoSlab-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoSlab';
    font-weight: 700;
    font-style: normal;
    src: font('roboto-slab/RobotoSlab-Bold.eot');
    src: font('roboto-slab/RobotoSlab-Bold.woff2') format('woff2'),
         font('roboto-slab/RobotoSlab-Bold.woff') format('woff'),
         font('roboto-slab/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
         font('roboto-slab/RobotoSlab-Bold.ttf') format('truetype');
}


// 2. Open Sans
// ====================================

@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
    src: font('open-sans/OpenSans-Regular.eot');
    src: font('open-sans/OpenSans-Regular.woff2') format('woff2'),
         font('open-sans/OpenSans-Regular.woff') format('woff'),
         font('open-sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
         font('open-sans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: normal;
    src: font('open-sans/OpenSans-SemiBold.eot');
    src: font('open-sans/OpenSans-SemiBold.woff2') format('woff2'),
    font('open-sans/OpenSans-SemiBold.woff') format('woff'),
    font('open-sans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    font('open-sans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: normal;
    src: font('open-sans/OpenSans-Bold.eot');
    src: font('open-sans/OpenSans-Bold.woff2') format('woff2'),
         font('open-sans/OpenSans-Bold.woff') format('woff'),
         font('open-sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
         font('open-sans/OpenSans-Bold.ttf') format('truetype');
}


// 3. Icomoon
// ====================================

@font-face {
    font-family: 'Icomoon';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: font('icomoon/icomoon.eot');
    src: font('icomoon/icomoon.ttf') format('truetype'),
    font('icomoon/icomoon.woff') format('woff'),
    font('icomoon/icomoon.eot?#iefix') format('embedded-opentype');
}