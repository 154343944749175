/*
 * [ settings / index ]
 */

// -----------------------------------------------------------------------------

$z-index: (
    header: 1010,
    modal:  1020,
    alert:  1030
);

@function index($key) {
    @return map-get($z-index, $key);
}
