/*
 * [ settings / layout ]
 */

// -----------------------------------------------------------------------------

$container-max-width: 1440px;

$grid-columns: 12;
$grid-gap: 16px;
